import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Main from 'layouts/Main';
import Container from 'components/Container';

// import p1 from 'assests/Photos/Events/Dussasra/1.png'

const Home = ()=> {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/home-static/events/Dussasra/1.webp`;

  const mock = [
    {
      media: p1,
      href: '/archive2021-22/felicitationofwinners',
    
      description:
        'School Programs',
    },
   
    {
      media: 'http://startup.nic.club/wp-content/uploads/2015/01/1171.png',
      href: '/science-club',
      companyLogo:
        'https://www.svgrepo.com/show/61264/science.svg',
      description:
        'Science',
    },
    {
      media: 'https://i.imgur.com/hvzPwnc.png',
      href: '/math-club',
      companyLogo:
        'https://www.svgrepo.com/show/116135/mathematical-symbols-in-four-squares.svg',
      description:
        'Math',
    },
    {
      media: 'https://i.imgur.com/cVhGAkw.png',
      href: '/quize-club',
      companyLogo:
        'https://st2.depositphotos.com/1915171/6207/v/950/depositphotos_62072697-stock-illustration-quiz-icon.jpg?forcejpeg=true',
      description:
        'Quiz',
    },
    {
      media: 'https://1.bp.blogspot.com/-GFVSU2IMPTs/YDzP0jUblgI/AAAAAAAABYU/biaHILeEviInZz1OF_9mL2MAzBSzM5s_gCLcBGAsYHQ/s320/WhatsApp%2BImage%2B2021-02-14%2Bat%2B10.30.31%2BPM.jpeg',
      href: '/ev-day-class6-12-202',
      companyLogo:
        'https://www.svgrepo.com/show/118643/eco-leaf.svg',
      description:
        'Eco',
    },
    {
      media: 'https://i.imgur.com/MrJ1hZQ.png',
      href: '/humanity-club',
      companyLogo:
        'https://i.imgur.com/Z8HU8S9.jpg',
      description:
        'Humanity',
    },
    {
      media: 'https://i.imgur.com/tau1RwO.png',
      href: '/cyber-club',
      companyLogo:
        'https://i.imgur.com/zSTHF6H.png',
      description:
        'Cyber',
    },
    {
      media: 'https://i.pinimg.com/originals/73/29/ec/7329ec81dcceead61217a205c941a3b3.jpg',
      href: '/health-wellness-club',
      companyLogo:
        'https://i.pinimg.com/originals/73/29/ec/7329ec81dcceead61217a205c941a3b3.jpg',
      description:
        'Health & Wellness Club',
    },
    {
      media: 'https://c8.alamy.com/comp/2BNPJW2/creative-tree-logo-and-icon-vector-template-2BNPJW2.jpg',
      href: '/integrity-heritage-club',
      companyLogo:
        'https://c8.alamy.com/comp/2BNPJW2/creative-tree-logo-and-icon-vector-template-2BNPJW2.jpg',
      description:
        'Integrity & Heritage Club',
    },
    {
      media: 'https://cdn2.vectorstock.com/i/1000x1000/63/41/book-icon-logo-design-vector-28056341.jpg',
      href: '/reading-club',
      companyLogo:
        'https://cdn2.vectorstock.com/i/1000x1000/63/41/book-icon-logo-design-vector-28056341.jpg',
      description:
        'Reading Club',
    },
    {
      media: 'https://cdn2.vectorstock.com/i/1000x1000/63/41/book-icon-logo-design-vector-28056341.jpg',
      href: '/literay-club',
      companyLogo:
        'https://cdn2.vectorstock.com/i/1000x1000/63/41/book-icon-logo-design-vector-28056341.jpg',
      description:
        'literary club',
    },
  ];

  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Main>
    <Container>
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            fontWeight: 700 
          }}
          gutterBottom
          // color={'secondary'}
          align={'center'}
          variant={'h4'}
          
        >
          Our Clubs
        </Typography>
        <br />
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h6'}
          align={'center'}
        >
          See how we are involving in 
          <br />
          various activities
        </Box>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              // component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  component = 'img'
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
               
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    align={'left'}
                    sx={{ fontWeight: 700 }}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    href= {item.href}
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    Learn More
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Container>
    </Main>
  );
};

export default Home;
